<template lang="pug">
div
  include ../../configs/template
  TableV2(
    :headers="headers"
    :items="listUsers.results || []"
    :pageCount="listUsers.count"
    :currentPage="listUsers.current"
    :querySearch="loadData"
    :isLoading="isLoading"
    :actions="actions"
    :hide-filters="user?.type_user === 'support'")
</template>

<script>
import { checkAccess } from '@/mixins/permissions.js'
import { queryString } from '@/mixins/main.js'
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.go',
          to: (item) => ({ name: 'account-management-info', params: { documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right'
        }
      ],
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      listUsers: state => state.user.userList,
      user: state => state.main.user
    }),
    headers () {
      const headers = [
        { value: 'id', text: 'ID', sortable: false },
        { value: 'userFullName', text: this.$t('fullName'), sortable: false },
        { value: 'type_user', text: this.$t('role') },
        { value: 'email', text: this.$t('email'), sortable: false },
        { value: 'support_code', text: this.$t('supportСode'), sortable: false }
      ]
      if (this.user.type_user !== 'support') {
        headers.push({ value: 'event', text: this.$t('actions'), class: 'mw-0', sortable: false })
      }
      return headers
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'accountManagement', access: checkAccess('accountManagement', 'view') })
  },
  methods: {
    ...mapActions(['getUsers', 'postSearchUserForSupport']),

    loadData (filter) {
      if (this.user.type_user === 'support') {
        if (filter && !filter.hasOwnProperty('search')) return false
        this.postSearchUserForSupport({ code: filter.search })
      } else this.getUsers(queryString(filter))
    }
  }
}
</script>
